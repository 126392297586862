export default [
  {
    header: 'CRM Admin',
  },
  {
    title: 'Lista Aziende',
    route: 'crm-aziende',
    icon: 'UsersIcon',
  },
  {
    title: 'Richieste Aziende',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'In attesa',
        route: 'crm-leads-in-attesa',
      },
      {
        title: 'In corso',
        route: 'crm-leads-in-corso',
      },
      {
        title: 'Concluse',
        route: 'crm-leads-conlcuse',
      },
      {
        title: 'Nel cestino',
        route: 'crm-leads-nel-cestino',
      },
    ],
  },
  {
    title: 'Ordini Aziende',
    route: 'crm-ordini',
    icon: 'BoxIcon'
  },
  
]

<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://bio-medicalpharma.com/it/"
        target="_blank"
      >Biomedical Pharma srl</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>

      <b-link href="https://www.facebook.com/BIOMEDICALPHARMAIT" target="_blank" class="ml-2">IG</b-link>
      <b-link href="https://www.instagram.com/biomedical_pharmait/" target="_blank" class="ml-2">FB</b-link>
      <b-link href="https://www.linkedin.com/company/biomedical-pharma-srl" target="_blank" class="ml-2">LK</b-link>
    </span>

    <span class="float-md-right d-none d-md-block">Sviluppato da <b-link
        class="ml-25"
        href="https://www.rubisco.it/"
        target="_blank"
      >Rubisco.it</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>

/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

/*
import dashboard from './dashboard'
import demomio from './demo-mio'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'
*/

import rubisco_dashboard from './rubisco_dashboard'
import rubisco_catalogo_prodotti from './rubisco_catalogo_prodotti'
import rubisco_la_tua_azienda from './rubisco_la_tua_azienda'
import rubisco_crm from './rubisco_crm'
import demomio from './demo-mio'


// Array of sections
//export default [...rubisco_dashboard, ...rubisco_catalogo_prodotti, ...rubisco_la_tua_azienda, ...rubisco_crm, ...dashboard, ...demomio, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]

export default [...rubisco_dashboard, ...rubisco_catalogo_prodotti, ...rubisco_la_tua_azienda, ...rubisco_crm]
